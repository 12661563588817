const Skeleton = ({ index }) => {
  const style = { animationDelay: `${index * 0.18}s` };
  return (
    <>
      <div className="white relative h-full border-b border-r border-darkblue-500/50 p-8 pb-[80px] lg:min-h-[470px]">
        <h2
          className="min-h-[40px] animate-pulse rounded bg-light-grey-600 text-3xl font-bold"
          style={style}
        ></h2>
        <div className="mb-4 mt-8 grid grid-cols-2 gap-4">
          <div>
            <div
              className="mb-2 block h-[20px] animate-pulse truncate rounded bg-light-grey-600"
              style={style}
            ></div>
            <div
              className="mb-2 block h-[20px] animate-pulse truncate rounded bg-light-grey-600"
              style={style}
            ></div>
          </div>
          <div>
            <div
              className="mb-2 block h-[20px] animate-pulse truncate rounded bg-light-grey-600"
              style={style}
            ></div>
            <div
              className="mb-2 block h-[20px] animate-pulse truncate rounded bg-light-grey-600"
              style={style}
            ></div>
            <div
              className="mb-2 block h-[20px] animate-pulse truncate rounded bg-light-grey-600"
              style={style}
            ></div>
          </div>
        </div>

        <div
          className="mb-4 h-[25px] animate-pulse rounded bg-light-grey-600"
          style={style}
        ></div>
        <div
          className="mb-4 h-[25px] animate-pulse rounded bg-light-grey-600"
          style={style}
        ></div>
        <div
          className="mb-4 h-[25px] animate-pulse rounded bg-light-grey-600"
          style={style}
        ></div>
      </div>
    </>
  );
};

export default Skeleton;
