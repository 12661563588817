import React from "react";

export default function Search({
  title,
  imageurl,
  species,
  formats,
  locations,
}) {
  const [allSpecies, setAllSpecies] = React.useState([]);
  const [allFormats, setAllFormats] = React.useState([]);
  const [allLocations, setAllLocations] = React.useState([]);

  const [filterSpecies, setFilterSpecies] = React.useState(null);
  const [filterFormats, setFilterFormats] = React.useState(null);
  const [filterLocations, setFilterLocations] = React.useState(null);

  const [isSearching, setIsSearching] = React.useState(false);

  const [selectedSpecies, setSelectedSpecies] = React.useState(null);
  const [selectedFormats, setSelectedFormats] = React.useState(null);
  const [selectedLocations, setSelectedLocations] = React.useState(null);

  const [searchResults, setSearchResults] = React.useState([]);

  React.useEffect(() => {
    if (species) {
      setAllSpecies(JSON.parse(species));
    }

    if (formats) {
      setAllFormats(JSON.parse(formats));
    }

    if (locations) {
      setAllLocations(JSON.parse(locations));
    }
  }, [species, formats, locations]);

  const search = async () => {
    setIsSearching(true);

    let url = `/wp-json/wp/v2/producers?`;

    if (selectedSpecies) {
      url += `&species=${selectedSpecies.term_id}`;
    }

    if (selectedFormats) {
      url += `&formats=${selectedFormats.term_id}`;
    }

    if (selectedLocations) {
      url += `&locations=${selectedLocations.term_id}`;
    }

    url += `&per_page=100`;

    const response = await fetch(url);

    const data = await response.json();

    setSearchResults(data);

    setIsSearching(false);
  };

  return (
    <section
      className="flex flex-col items-center justify-center p-24"
      style={{
        background: `linear-gradient(rgba(0,34,68,0.7), rgba(0,34,68,0.7)), url(${imageurl}) no-repeat center center / cover`,
      }}
    >
      <h1 className="font-bold text-white">{title}</h1>

      <div className="mt-12 flex gap-4">
        <div className="relative z-50 flex gap-4 rounded-full bg-white p-2 px-8">
          <select
            className="text-darkblue rounded-full bg-yellow-500 px-8 font-bold"
            onChange={(e) => setFilterSpecies(e.target.value)}
            value={filterSpecies}
          >
            <option value="">Species</option>
            {allSpecies.map((x) => (
              <option value={x.term_id}>{x.name}</option>
            ))}
          </select>
          <select
            className="text-darkblue rounded-full bg-yellow-500 px-8 font-bold"
            onChange={(e) => setFilterFormats(e.target.value)}
            value={filterFormats}
          >
            <option value="">Formats</option>
            {allFormats.map((x) => (
              <option value={x.term_id}>{x.name}</option>
            ))}
          </select>
          <select
            className="text-darkblue rounded-full bg-yellow-500 px-8 font-bold"
            onChange={(e) => setFilterLocations(e.target.value)}
          >
            <option value="">Locations</option>
            {allLocations.map((x) => (
              <option value={x.term_id}>{x.name}</option>
            ))}
          </select>
        </div>
        <button
          className="text-darkblue rounded-full bg-yellow-500 px-8 font-bold"
          onClick={() => {
            setSelectedSpecies(filterSpecies);
            setSelectedFormats(filterFormats);
            setSelectedLocations(filterLocations);
            search();
          }}
        >
          {isSearching ? <SyncLoader color="#002244" /> : "Search"}
        </button>
      </div>
    </section>
  );
}
