import React from "react";
import { SyncLoader } from "react-spinners";
import { EventsManager } from "../utils/EventsManager";
import { useEffect } from "react";

export default function ProducerSearch({
  title,
  imageurl,
  species,
  formats,
  locations,
}) {
  const [allSpecies, setAllSpecies] = React.useState([]);
  const [allFormats, setAllFormats] = React.useState([]);
  const [allLocations, setAllLocations] = React.useState([]);
  const [isSearching, setIsSearching] = React.useState(false);

  const [filterSpecies, setFilterSpecies] = React.useState(null);
  const [filterFormats, setFilterFormats] = React.useState(null);
  const [filterLocations, setFilterLocations] = React.useState(null);

  const [selectedSpecies, setSelectedSpecies] = React.useState(null);
  const [selectedFormats, setSelectedFormats] = React.useState(null);
  const [selectedLocations, setSelectedLocations] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    if (species) {
      setAllSpecies(JSON.parse(species));
      EventsManager.emit("directory:species", JSON.parse(species));
    }

    if (formats) {
      setAllFormats(JSON.parse(formats));
      EventsManager.emit("directory:formats", JSON.parse(formats));
    }

    if (locations) {
      setAllLocations(JSON.parse(locations));
      EventsManager.emit("directory:locations", JSON.parse(locations));
    }
  }, [species, formats, locations]);

  useEffect(() => {
    EventsManager.subscribe("search:species", (data) => {
      setCurrentPage(1);
      setSelectedSpecies(data.term_id);
      setFilterSpecies(data.term_id);
    });

    EventsManager.subscribe("search:formats", (data) => {
      setCurrentPage(1);
      setSelectedFormats(data.term_id);
      setFilterFormats(data.term_id);
    });

    EventsManager.subscribe("search:locations", (data) => {
      setCurrentPage(1);
      setSelectedLocations(data.term_id);
      setFilterLocations(data.term_id);
    });

    EventsManager.subscribe("search:page", (data) => {
      setCurrentPage(data);
    });

    setTimeout(() => {
      search(selectedSpecies, selectedFormats, selectedLocations);
    }, 100);
  }, []);

  useEffect(() => {
    search(selectedSpecies, selectedFormats, selectedLocations);
  }, [selectedFormats, selectedLocations, selectedSpecies, currentPage]);

  const search = async (
    selectedSpecies,
    selectedFormats,
    selectedLocations
  ) => {
    setIsSearching(true);

    console.log(
      `searching for ${selectedSpecies}, ${selectedFormats}, ${selectedLocations}`
    );

    let url = `/wp-json/wp/v2/producers?`;

    if (selectedSpecies) {
      url += `&species=${selectedSpecies}`;
    }

    if (selectedFormats) {
      url += `&formats=${selectedFormats}`;
    }

    if (selectedLocations) {
      url += `&locations=${selectedLocations}`;
    }

    url += `&per_page=${currentPage === 1 ? 22 : 24}`;

    console.log(url);
    if (currentPage > 1) {
      url += `&page=${currentPage}`;
    }

    const response = await fetch(url);

    const data = await response.json();

    const totalResultsFromHeader = response.headers.get("x-wp-total");

    const totalPagesFromHeader = response.headers.get("x-wp-totalpages");

    EventsManager.emit("directory:search:results", totalResultsFromHeader);
    EventsManager.emit("directory:search:pages", totalPagesFromHeader);

    if (currentPage === 1) {
      console.log("emitting directory:search");
      EventsManager.emit("directory:search", data);
    } else {
      console.log("emitting directory:search:append");
      EventsManager.emit("directory:search:append", data);
    }

    setIsSearching(false);
  };

  return (
    <div className="mt-12 flex flex-wrap justify-center gap-4">
      <div className="relative z-50 flex flex-wrap gap-4 rounded bg-white p-2 px-8 py-3 lg:rounded-full">
        <select
          className="text-darkblue lg:border-wjote w-full border border-darkblue-500/50 px-8 py-2 outline-none lg:w-[calc(33%_-_1rem)] lg:border-none"
          onChange={(e) => setFilterSpecies(e.target.value)}
          value={filterSpecies}
        >
          <option value="">All Species</option>
          {allSpecies.map((x, index) => (
            <option value={x.term_id} key={`species-${index}`}>
              {x.name}
            </option>
          ))}
        </select>
        <select
          className="text-darkblue lg:border-wjote w-full border border-darkblue-500/50 px-8 py-2 outline-none lg:w-[calc(33%_-_1rem)] lg:border-none"
          onChange={(e) => setFilterFormats(e.target.value)}
          value={filterFormats}
        >
          <option value="">All Formats</option>
          {allFormats.map((x, index) => (
            <option value={x.term_id} key={`formats-${index}`}>
              {x.name}
            </option>
          ))}
        </select>
        <select
          className="text-darkblue lg:border-wjote w-full border border-darkblue-500/50 px-8 py-2 outline-none lg:w-[calc(33%_-_1rem)] lg:border-none"
          onChange={(e) => setFilterLocations(e.target.value)}
          value={filterLocations}
        >
          <option value="">All Locations</option>
          {allLocations.map((x, index) => (
            <option value={x.term_id} key={`locations-${index}`}>
              {x.name}
            </option>
          ))}
        </select>
      </div>
      <button
        className="text-darkblue rounded-full bg-yellow-500 px-8 py-4 font-bold"
        onClick={() => search(filterSpecies, filterFormats, filterLocations)}
      >
        Search
      </button>
    </div>
  );
}
