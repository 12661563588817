// components/registerComponents.js
import reactToCustomElement from '../utils/web.components'
import MyTitle from './MyTitle/MyTitle'
import ProducerDirectory from './ProducerDirectory/ProducerDirectory'
import ProducerSearch from './ProducerDirectory/ProducerSearch'

const components = {
  'my-title': MyTitle,
  'producer-directory': ProducerDirectory,
  'producer-search': ProducerSearch,
}

for (const [name, Component] of Object.entries(components)) {
  reactToCustomElement(Component, name)
}
