import React from "react";
import Search from "./components/Search";
import FilterBox from "./components/FilterBox";
import { SyncLoader } from "react-spinners";
import { BiMapPin } from "react-icons/bi";
import {
  AiOutlineArrowRight,
  AiOutlinePhone,
  AiOutlineMail,
} from "react-icons/ai";
import { BiCog } from "react-icons/bi";
import { GiFishing } from "react-icons/gi";
import { EventsManager } from "../utils/EventsManager";
import Skeleton from "./Skeleton";
import { useMemo } from "react";

export default function ProducerDirectory() {
  const [searchResults, setSearchResults] = React.useState([]);
  const [allSpecies, setAllSpecies] = React.useState([]);
  const [allFormats, setAllFormats] = React.useState([]);
  const [allLocations, setAllLocations] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    EventsManager.subscribe("directory:search", (data) => {
      console.log("setting results");
      setSearchResults(data);
    });
    EventsManager.subscribe("directory:search:append", (data) => {
      console.log("appending pages");
      setSearchResults((prev) => [...prev, ...data]);
    });
    EventsManager.subscribe("directory:species", (data) => {
      setAllSpecies(data);
    });
    EventsManager.subscribe("directory:formats", (data) => {
      setAllFormats(data);
    });
    EventsManager.subscribe("directory:locations", (data) => {
      setAllLocations(data);
    });
    EventsManager.subscribe("directory:search:results", (data) => {
      setTotal(data);
    });
    EventsManager.subscribe("directory:search:pages", (data) => {
      setTotalPages(data);
    });
  }, []);

  const scrollToTop = () => {
    document
      .getElementById("top-search")
      .scrollIntoView({ behavior: "smooth" });
  };

  const sortedSearchResults = useMemo(() => {
    return searchResults.sort((a, b) => {
      if (a.title.rendered < b.title.rendered) {
        return -1;
      }
      if (a.title.rendered > b.title.rendered) {
        return 1;
      }
      return 0;
    });
  }, [searchResults]);

  return (
    <>
      <div className="grid grid-cols-1 items-center justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {searchResults.length === 0 && (
          <>
            <div className="relative h-full min-h-[250px] bg-darkblue-500 p-8 text-white lg:min-h-[470px]" />
            {/* loop from 1 to 10 and display skeletons */}
            {Array.from(Array(10).keys()).map((x, index) => {
              return <Skeleton key={index} index={index} />;
            })}
          </>
        )}
        {searchResults.length > 0 && (
          <>
            <div
              className="relative h-full min-h-[250px] bg-darkblue-500 p-8 text-white lg:min-h-[470px]"
              id="top-search"
            >
              <h2 class="absolute bottom-8 right-8 font-bold">
                {total} Results
              </h2>
            </div>
            {searchResults.length > 0 &&
              searchResults.map((x) => {
                let speciesString = "";
                let formatsString = "";
                let locationsString = "";

                let species = [];
                let formats = [];
                let locations = [];

                if (x.species && x.species.length > 0) {
                  const speciesIds = x.species;
                  species = allSpecies.filter((x) =>
                    speciesIds.includes(x.term_id)
                  );

                  speciesString = species.map((x) => x.name).join(", ");
                }

                if (x.formats && x.formats.length > 0) {
                  const formatIds = x.formats;
                  formats = allFormats.filter((x) =>
                    formatIds.includes(x.term_id)
                  );
                  formatsString = formats.map((x) => x.name).join(", ");
                }

                if (x.locations && x.locations.length > 0) {
                  const locationIds = x.locations;
                  locations = allLocations.filter((x) =>
                    locationIds.includes(x.term_id)
                  );
                  locationsString = locations.map((x) => x.name).join(", ");
                }

                return (
                  <>
                    <div className="relative h-full border-b border-r border-darkblue-500/50 bg-light-grey-500 p-8 pb-[80px] text-darkblue-500">
                      <h2
                        className="min-h-[80px] text-3xl font-bold"
                        dangerouslySetInnerHTML={{ __html: x.title.rendered }}
                      />
                      <div className="mb-4 mt-8 grid grid-cols-2 gap-4">
                        <div>
                          {x.acf.phone && (
                            <a
                              className="mb-2 block truncate"
                              href={`tel:${x.acf.phone}`}
                            >
                              <strong className="block">
                                <AiOutlinePhone className="mr-2 inline text-[20px]" />
                                Phone
                              </strong>{" "}
                              {x.acf.phone}
                            </a>
                          )}
                          {x.acf.email && (
                            <a
                              className="mb-2 block truncate"
                              href={`mailto:${x.acf.email}`}
                              target="_blank"
                            >
                              <strong className="block">
                                <AiOutlineMail className="mr-2 inline text-[20px]" />
                                Email
                              </strong>{" "}
                              {x.acf.email}
                            </a>
                          )}
                        </div>
                        {x.acf.address && (
                          <div>
                            <strong className="mb-2 flex items-center">
                              <BiMapPin className="mr-2 inline text-[20px]" />
                              Address
                            </strong>
                            {/* link to gmaps directions */}
                            <a
                              href={`https://www.google.com/maps/search/${x.acf.address}`}
                              target="_blank"
                              rel="noreferrer"
                              className="block"
                              dangerouslySetInnerHTML={{
                                __html: x.acf.address
                                  ?.split(",")
                                  .join("<br />"),
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {x.acf.website && (
                        <a
                          className="absolute bottom-4 right-4 flex h-[40px] min-w-[40px] items-center justify-center rounded-full border border-darkblue-500 px-6 transition-all hover:bg-darkblue-500 hover:text-white"
                          href={x.acf.website}
                          target="_blank"
                        >
                          Visit website
                          <AiOutlineArrowRight className="m-auto h-[20px] w-[20px]" />
                        </a>
                      )}
                      <div>
                        <strong className="mb-2 flex items-center">
                          <GiFishing className="mr-2 inline text-[20px]" />
                          Species
                        </strong>{" "}
                        <div className="flex flex-wrap gap-2">
                          {species.map((x, index) => {
                            return (
                              <div
                                className="cursor-pointer rounded border border-darkblue-500 p-2 text-darkblue-500"
                                key={index}
                                onClick={() => {
                                  EventsManager.emit("search:species", x);
                                  scrollToTop();
                                }}
                              >
                                {x.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {formats.length > 0 && (
                        <div className="mt-4">
                          <strong className="mb-2 flex items-center">
                            <BiCog className="mr-2 inline text-[20px]" />
                            Formats
                          </strong>{" "}
                          <div className="flex flex-wrap gap-2">
                            {formats.map((x, index) => {
                              return (
                                <div
                                  className="cursor-pointer rounded bg-yellow-500 p-2 font-bold text-black"
                                  key={index}
                                  onClick={() => {
                                    EventsManager.emit("search:formats", x);
                                    scrollToTop();
                                  }}
                                >
                                  {x.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {locations.length > 0 && (
                        <div className="mt-4">
                          <strong className="mb-2 flex items-center">
                            <BiMapPin className="mr-2 inline text-[20px]" />
                            Locations
                          </strong>{" "}
                          <div className="flex flex-wrap gap-2">
                            {locations.map((x, index) => {
                              return (
                                <div
                                  className="cursor-pointer rounded bg-lightblue p-2 text-white"
                                  key={index}
                                  onClick={() => {
                                    EventsManager.emit("search:locations", x);
                                    scrollToTop();
                                  }}
                                >
                                  {x.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            {searchResults.length > 0 && currentPage < totalPages && (
              <button
                className="relative h-full min-h-[470px] bg-darkblue-500 p-8 text-white"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  EventsManager.emit("search:page", currentPage + 1);
                }}
              >
                <h2 class="absolute bottom-8 right-8 font-bold">
                  See more
                  <AiOutlineArrowRight className="ml-4 inline-block h-[50px] w-[50px] rounded-full border border-white p-2" />
                </h2>
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
}
