import React, { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'

interface ReactComponentProps {
  children?: ReactNode
  [key: string]: any // You might want to replace this with more specific prop types if needed
}

function reactToCustomElement(
  Component: React.ComponentType<ReactComponentProps>,
  componentName: string,
  observedAttrs: string[] = [] // Optionally pass the attributes you want to observe
): void {
  class ReactCustomElement extends HTMLElement {
    constructor() {
      super()
      setTimeout(() => this._update())
    }

    private _update(): void {
      const root = createRoot(this)

      const props: ReactComponentProps = {}

      // Map observed attributes to props
      for (const attr of Array.from(this.attributes)) {
        props[attr.name] = attr.value
      }

      root.render(<Component {...props}>{this.textContent}</Component>)
    }

    // Use the passed observed attributes
    static get observedAttributes(): string[] {
      return observedAttrs
    }

    attributeChangedCallback(
      name: string,
      oldValue: string,
      newValue: string
    ): void {
      this._update()
    }
  }

  // Define the custom element using the provided name
  customElements.define(componentName, ReactCustomElement)
}

export default reactToCustomElement
