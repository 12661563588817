document.addEventListener("DOMContentLoaded", function () {
  var openNav = document.querySelector(".navmenu");
  var body = document.querySelector("body");
  var headerSection = document.querySelector(".headersection");

  if (openNav) {
    openNav.addEventListener("click", function () {
      this.classList.toggle("closenav");
      body.classList.toggle("nav-open");
    });
  }

  function adjustPaddingTop() {
    var headersection = document.querySelector(".headersection");
    var wpSiteBlocks = document.querySelector(
      ".wp-site-blocks .entry-content.wp-block-post-content"
    );

    if (headersection && wpSiteBlocks) {
      var headerHeight = headersection.offsetHeight;
      var wpAdminBar = document.querySelector("#wpadminbar");
      var wpAdminBarHeight = wpAdminBar ? wpAdminBar.offsetHeight : 0;
      console.log(wpAdminBarHeight);
      wpSiteBlocks.style.marginTop = headerHeight - wpAdminBarHeight + "px";
    }
  }

  function handleMobileNav() {
    const menuItems = document.querySelectorAll(".menu-item-has-children");

    const handleMenuClick = (e) => {
      const menuItem = e.currentTarget;
      const submenu = menuItem.querySelector(".sub_menu");

      // Ensure submenu exists and the screen width is less than or equal to 1024px (mobile)
      if (submenu && window.innerWidth <= 1024) {
        // Toggle submenu visibility
        if (submenu.classList.contains("open")) {
          submenu.classList.remove("open");
          submenu.style.display = "none";
        } else {
          e.preventDefault(); // Stop default link behavior
          // Close all other open submenus
          document.querySelectorAll(".sub_menu.open").forEach((otherMenu) => {
            otherMenu.classList.remove("open");
            otherMenu.style.display = "none";
          });

          // Show the clicked submenu
          submenu.classList.add("open");
          submenu.style.display = "block";
        }
      }
    };

    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", handleMenuClick);
    });
  }

  window.addEventListener("resize", adjustPaddingTop);
  adjustPaddingTop();
  handleMobileNav();
});
