import React from 'react'

function MyTitle({ value, children }: { 
  value?: string,
  children?: React.ReactNode }) {
  
  const [content, setContent] = React.useState(children)

  return (
    <h1
      onMouseOver={(e) => {
        setContent('Hello World')
      }}
      className="text-center text-4xl font-bold"
    >
      {value} - {content}
    </h1>
  )
}

export default MyTitle
